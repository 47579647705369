let _window = $(window),
    _html = $('html'),
    _body = $('body'),
    adjust,speed,href,target,position;

/* PC menu */
let _menuHover = () => {
  if (window.outerWidth >= 768) {
    $('.navi__service').on({
      'mouseenter':function(){
        $('.navi__service__txt').addClass('show');
        $('.navi__service__inner').addClass('show');
        $('.navi__service__curtain.pc').addClass('show');
      }
    });

    $('.navi__service__curtain').on({
      'mouseenter':function(){
        $('.navi__service__txt').removeClass('show');
        $('.navi__service__inner').removeClass('show');
        $('.navi__service__curtain.pc').removeClass('show');
      }
    });

    $('.navi__service__inner dl a').on("click", function () {
      $('.navi__service__txt').removeClass('show');
      $('.navi__service__inner').removeClass('show');
      $('.navi__service__curtain.pc').removeClass('show');
    });
  } else {
    $('.navi__service__txt').removeClass('show');
    $('.navi__service__inner').removeClass('show');
    $('.navi__service__curtain.pc').removeClass('show');
  }
}

/* SP menu */
let _menuBtn = () => {
  if (window.outerWidth <= 767) {
    let _menu = $('.menu'),
        _navi = $('.navi'),
        _curtain = $('.navi__service__curtain.sp'),
        _close = $('.navi__service__curtain,.navi a');
    _menu.on("click", function () {
      _navi.show();
      setTimeout(function () {
        _navi.show().addClass('show');
      }, 100);
      _curtain.addClass('show');
      _body.css({ 'overflow': 'hidden' });
    });
    _close.on("click", function () {
      _navi.removeClass('show');
      _curtain.removeClass('show');
      _body.removeAttr('style');
      setTimeout(function () {
        _navi.removeAttr('style');
      }, 250);
    });
  } else {
    $('.navi').removeClass('show').removeAttr('style');
    $('.navi__service__curtain.sp').removeClass('show');
    _body.removeAttr('style');
  }
}

/* PC menu */
let _headerBg = () => {
  if (window.outerWidth >= 768) {
    let _wsh = _window.scrollTop();
    if (_wsh == 0) {
      $('header').removeClass('bg');
    } else {
      $('header').addClass('bg');
    }
    _window.on("scroll", function () {
      _wsh = $(this).scrollTop();
      if (_wsh == 0) {
        $('header').removeClass('bg');
      } else {
        $('header').addClass('bg');
      }
    });
  }
}

/* Page Top */
let _pageTop = () => {
  let _wsp = _window.scrollTop();
  if (_wsp == 0) {
    $('.pagetop').addClass('hide');
  } else {
    $('.pagetop').removeClass('hide');
  }
  _window.on("scroll", function () {
    _wsp = $(this).scrollTop();
    if (_wsp == 0) {
      $('.pagetop').addClass('hide');
    } else {
      $('.pagetop').removeClass('hide');
    }
  });
}

/* 初回ページ内スクロール */
let _firstScrollAction = () => {
  if (!$(".ez-toc-list").length) {
    adjust = $("header").height();
    speed = 500;
    target = location.hash;
    if(target) {
      position = $(target).offset().top - adjust;
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
    }
    return false;
  }
}


/* スムーススクロール */
let _smoothScrollAction = () => {
  $('a[href^="#"]').not('.ez-toc-link').on("click", function () {
      adjust = $("header").height();
      speed = 500;
      href = $(this).attr("href");
      target = $(href == "#" || href == "" ? 'html' : href);
      position = target.offset().top - adjust;
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
      return false;
  });
}

$(function () {
  _window.scrollTop(0);
  _menuHover();
  _menuBtn();
  _headerBg();
  _pageTop();
  _window.on("load resize", function () {
    _menuHover();
    _menuBtn();
  });
  _smoothScrollAction();
  setTimeout(function () {
    _firstScrollAction();
  }, 100);
});